import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasThreeTheme } from 'common/src/theme/saasThree';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/about/saasThree.style';

import BannerSection from 'common/src/containers/about/Banner';
import Navbar from 'common/src/containers/gojoylab/Navbar';
import TrialSection from 'common/src/containers/about/Trial';
import Newsletter from 'common/src/containers/about/Newsletter';
import Footer from 'common/src/containers/gojoylab/Footer';
import '@redq/reuse-modal/es/index.css';
import SEO from '../components/seo';

export default () => {
  return (
    <ThemeProvider theme={saasThreeTheme}>
      <Fragment>
        <SEO title="GojoyLab | About" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />

          <TrialSection />
          <Newsletter />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};